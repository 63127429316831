<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="请选择" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>“Whistle”直播协议</h2>
		<p>《Whistle直播协议》，是CLICK ADVERTISING & MEDIA PTE. LTD.（以下简称“我方”）和你方（你方为自然人、法人或其他组织）所约定的规范双方权利和义务的具有法律效力的电子协议，下称“本协议”。你方勾选“我同意”或点击“我已阅读并遵守该协议”按钮，即表示你方已经仔细阅读、充分理解并完全地毫无保留地接受本协议的所有条款。</p>
	
		<h2>第一条 总则</h2>
		<p>1、Whistle平台是指由CLICK ADVERTISING & MEDIA PTE. LTD.及其关联公司包括但不限于CLICK ADVERTISING & MEDIA PTE. LTD.自有直播平台。你方根据我方注册要求及规则，在我方合法经营的Whistle平台（以下简称“平台”）上申请成为我方的直播服务提供方（或称“直播方”），为我方平台用户提供在线直播视频内容的直播服务，你方在我方平台提供服务期间均应视为协议期内。我方不事先审核前述被上载的、由你方参与、编辑、制作的视频内容，也不主动对该等视频进行任何编辑、整理、修改、加工。</p>
		<p>2、签署本协议前，你方已充分了解我方之各项规则及要求，且有条件及有能力、资格履行本协议约定的直播方职责及义务。本协议对你方构成有效的、带有约束力的、可强制执行的法定义务，你方对本协议下所有条款及定义等内容均已明确知悉，并无疑义。</p>
		<p>3、你方承诺并声明在为我方提供服务时符合所在地法律的相关规定，不得以履行本协议名义从事其他违反中国及所在地法律规定的行为。</p>
		<p>4、你方与我方不构成任何劳动法律层面的雇佣、劳动、劳务关系，我方无需向你方支付社会保险金和福利。</p>
		<p>5、未经我方事先书面同意，你方不得在第三方竞争平台上从事任何与直播相关的行为。</p>
		<p>6、你方在注册成为Whistle主播或在Whistle平台进行直播服务前已确认，未与任何第三方平台签署或存在有效存续的独家直播直播协议。</p>
		<p>7、在双方合作期间，你方直播、上传、发布或传输等内容的相关权利，适用于《Whistle用户服务协议》中条款的约定。</p>
		
		<h2>第二条 我方权利义务</h2>
		<p>1、我方有权制定平台运营制度及对直播方的管理规则，并将其作为本协议的一部分，有权对你方进行管理和监督，有权根据运营情况对相应规则做出调整或变更，你方对此表示理解和同意。</p>
		<p>2、我方有权对你方进行考察、评判，以确立（取消）对你方的奖励或处罚，具体考察项目及标准由我方另行制定，无需额外征得你方同意。如我方要求与你方另行签订正式的直播合作协议的，你方不得拒绝或以其他形式变相拒绝签订【否则你方应一次性向我方支付违约金【50000】（大写：伍万）元】。</p>
		<p>3、我方有权对你方的直播服务提出改进建议和意见，你方应在收到我方的建议和意见后【3】个工作日内进行相应的整改，否则我方有权限制、关闭、回收、或终止你方对Whistle直播间的使用，相应扣减应支付给你方的服务费用（若有）可能会给你方造成一定的损失，该损失由你方自行承担，我方不承担任何责任。</p>
		<p>4、我方对你方进行的直播直播相关事宜拥有最终决定权。</p>
		<p>5、我方有权使用你方的的名称（包括但不限于你方真实姓名、笔名、网名、曾用名及任何代表你方身份的文字符号）、肖像（包括但不限于真人肖像及卡通肖像等）进行我方平台的各类宣传。</p>
		<p>6、我方负责提供平台技术服务支持，同时负责平台服务费用结算（若有）。</p>
	
		<h2>第三条 你方权利义务</h2>
		<p>1、你方应当使用真实身份信息及个人资料，不得以虚假、冒用的居民身份信息、企业注册信息、组织机构代码信息进行注册并认证。若你方的个人资料有任何变动，应及时更新。我方禁止未成年人直播，若法定监护人希望未成年人得以提供本协议约定的网络直播及直播服务的，必须以法定监护人身份加以判断该等服务内容是否适合于未成年人，并由法定监护人承担因此而导致的一切后果。你方承诺不会因执行本协议损害任何第三方合法利益，你方接受并履行本协议不违反任何对你有约束力的法律文件，亦不会使我方对任何第三方承担责任。</p>
		<p>2、你方自己承担进行直播直播所需要的网络、支持视频和语音的设备，并保证直播图像清、语音质量清晰、稳定。</p>
		<p>3、你方承诺，愿意遵照平台设定的直播间申请程序，提交平台所需的申请材料并自愿缴付相应的保证金。</p>
		<p>4、你方承诺直播房间必须作直播直播用途，不得用于其他任何非直播直播性质的活动。 <b>你方开展直播带货时，你方及你方供应商应该遵守中国法律规定并履行我方颁布的《商家服务协议》的有关规定。</b></p>
		<p>5、你方开展本协议项下直播直播事项和/或在本平台上发布的所有信息/资讯/言论/内容等均不得含有任何违反中华人民共和国有关法律、法规及规定的内容，包括但不限于危害国家安全、淫秽色情、虚假、违法、诽谤（包括商业诽谤）、非法恐吓或非法骚扰、侵犯他人知识产权、人身权、商业秘密或其他合法权益以及有违公序良俗的内容或指向这些内容的链接。</p>
		<p>6、你方承诺积极维护我方及我方平台形象，你方不会做出有损于我方及/或我方平台形象或利益的行为，本协议期内及协议终止后，你方不会通过任何渠道（包括但不限于网站、博客、微博、微信、QQ聊天群、玩家聚会等）暗示或发布不利于我方及/或我方平台的言论。</p>
		<p>7、未经我方书面同意，你方不得在任何场合以任何形式（包括但不限于文字、口播、视频贴片等）提及第三方竞争平台的相关信息，不得引导或为我方平台现有用户、其他直播方及我方员工进入其他第三方竞争平台提供任何信息或便利，包括但不限于提供联络上的协助、进行说服工作等。</p>
	
		<h2>第四条 服务费用及结算</h2>
		<p>1、以你方为平台用户提供直播直播服务为前提，用户可对你方进行赠送虚拟礼物的消费，你方可根据我方结算要求及规则申请结算相关收益（如有），我方收取一定比例技术服务费并扣除相应税金后支付给你方。对于非正常手段获得的虚拟礼物消费，我方有权进行独立判断和处理。</p>
		<p>2、你方所获得的服务费用应当依据国家相关法律法规缴纳税金，我方将你方所获得的服务费用支付于你方在用户中心中填写的银行账户中，你方可在登陆我方平台后的收益记录中查询相关信息（结算数据为含税数据）。</p>
		<p>3、若你方为自然人，并在我方平台注册并通过个人认证的，则我方有权将你方所获得的服务费用支付于你方在用户中心填写的个人银行账户中；若你方为法人或其他组织，并在我方平台注册且通过机构认证的，则我方有权将你方所获得服务费用支付于你方在机构认证页面填写的机构账户中，但你方应当在我方付款前5个工作日内向我方提供等额有效的增值税专用发票（发票名目为直播服务费），因你方延迟提供发票导致我方付款延迟的，不构成我方违约。我方按照你方填写的账户支付服务费用，即视为我方已经履行了本协议约定的付款义务。若你方为法人或其他组织的，你方工作人员或旗下主播因管理及运营该帐号及其直播房间产生的费用，由你方与你方工作人员或旗下主播之间自行结算。若你方因该费用结算而引起纠纷、诉讼或赔偿给我方造成损失的（包括但不限于你方拖欠你方工作人员或旗下主播薪资费用时我方先行垫付其薪资的款项），我方有权在应付服务费用中先行扣除，不足部分我方有权向你方追偿。</p>
		<p>4、你方保证填写账户信息正确、真实、有效，如因账户信息造成我方的任何支付错误，由你方独自承担责任。同时，若你方需要变更帐户信息，需及时书面通知我方，新账户信息由你方提交申请且经我方审核通过后下一个结算月生效。</p>
	
		<h2>第五条 保密制度</h2>
		<p>1、你方应严格遵守我方的保密制度，承诺无限期保守我方的商业秘密。因你方违反约定使用或披露我方商业秘密和信息使我方遭受任何名誉、声誉或经济上的、直接或间接的损失，你方应赔偿我方人民币[ 100000 ]元违约金，不足以弥补我方损失的，你方还应赔偿我方损失。</p>
		<p>2、商业秘密是指由我方提供的、或者你方在双方合作期间了解到的、或者我方对第三方承担保密义务的，与我方业务有关的，能为我方带来经济利益，具有实用性的、非公知的所有信息，包括（但不限于）：技术信息、经营信息和与我方行政管理有关的信息和文件（含本协议及相关协议内容）、你方从我方获得的服务费用的金额和结算方式、标准、权利归属方式、授权方式、客户名单、其他直播员的名单、联系方式、服务费用、我方工作人员名单等不为公众所知悉的信息。</p>
		<p>3、你方应严格遵守本协议，未经我方书面授权或同意，对我方的商业秘密不得：</p>
		<ul>
			<li>（1）以任何方式向第三方或不特定的公众进行传播、泄露；</li>
			<li>（2）为非本协议的目的而使用我方的商业秘密。</li>
		</ul>
		<p>4、本协议终止后，你方应将我方商业秘密悉数返还我方，或在我方监督下，将记载我方商业秘密的全部文件销毁。</p>
		<p>5、本条规定在本协议终止后仍然有效。</p>
		
		<h2>第六条 协议的变更、解除、终止</h2>
		<p>1、我方有权在必要时变更、中止、终止本协议，并在相关页面进行通知，变更后的协议一旦在相关的页面上公布即有效代替原来的协议。本协议条款变更后，如你方继续为我方平台用户提供直播等直播服务，即视为你方已知悉并接受变更后的协议。如你方不同意我方对本协议的所作的任何变更，你方应立即书面通知我方并停止在我方平台进行的任何直播服务。</p>
		<p>2、双方就解除本协议协商一致即可终止协议。</p>
		<p>3、你方有下列情形之一，我方可以立即解除本协议，不需要提前通知：</p>
		<ul>
			<li>（1）我方发现你方违反对本协议所做的声明与承诺的；</li>
			<li>（2）因你方行为直接或间接给对我方利益造成重大损害的；</li>
			<li>（3）违反国家法律法规的；</li>
			<li>（4）违反本协议规定的其它义务；</li>
			<li>（5）以消极、不作为等不符合我方要求的方式履行本协议的（即使未构成违约），经我方通知后10日内仍未改正的；</li>
			<li>（6）因异常情形的出现，我方认为你方不适合进行本协议下服务事项，经我方通知后10日内异常情形仍未消除的；</li>
			<li>（7）因我方业务调整，不再进行直播服务业务的。</li>
		</ul>
		<p>4、由于本协议第六条第1、2款造成的协议解除、终止，我方按本协议第四条规定及我方平台实时政策约定与你方结算服务费用。</p>
		<p>5、由于本协议第六条第3款造成的协议解除、终止，我方有权扣除你方帐号中尚未结算的全部服务费用，并有权要求你方按约定承担违约责任。</p>
		
		<h2>第七条 违约责任</h2>
		<p>1、任何由于你方声明不实或违反其声明承诺事项导致他方向我方提起诉讼、索赔及/或导致我方声誉受损之后果，你方将承担我方因此产生的全部直接及间接费用、损失及赔偿，其中包括我方为诉讼支付的有关费用及律师费。</p>
		<p>2、除本协议另有约定外，你方违反本协议下任何规定或你方提供的服务不符合我方的要求，我方有权单方面采取相应限制或处罚措施，包括但不限于：限制、关闭、回收、或终止你方对Whistle直播间的使用，限制或停止某项单独服务（如视频直播）并根据实际情况决定是否恢复使用，扣除你方帐号中尚未结算的服务费用。</p>
	
		<h2>第八条 争议处理</h2>
		<p>因履行本协议而产生的任何争议，双方均应本着友好协商的原则加以解决。协商解决未果，任何一方均可以提请我方公司所在地或杭州市滨江区人民法院诉讼解决。</p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				options: [
					{
						value: '中文',
						label: '中文'
					}, {
						value: 'English',
						label: 'English'
					}, {
						value: '한국인',
						label: '한국인'
					}, {
						value: '日本語',
						label: '日本語'
					}
				],
				value: '',
				language: ''
			}
		},
		created() {
			// url获取参数
			this.language = this.$route.query.language;
			if (this.language == 'zh_CN') {
				this.$router.push({
					path: '/LiveAgreementZh'
				})
			}
			if (this.language == 'en_US') {
				this.$router.push({
					path: '/LiveAgreementEn'
				})
			}
		},
		methods: {
			clickChange(value) {
				this.value = value;
				if (value == '中文') {
					this.$router.push({
						path: '/LiveAgreementZh'
					})
				}
				if (value == 'English') {
					this.$router.push({
						path: '/LiveAgreementEn'
					})
				}
				if (value == '한국인') {
					this.$router.push({
						path: '/LiveAgreementKo'
					})
				}
				if (value == '日本語') {
					this.$router.push({
						path: '/LiveAgreementJa'
					})
				}
			},
		}
	}
</script>

<style>
	.container {
		padding: 0.1rem 0.3rem;		
	}
	
	.lang {
		text-align: right;
		margin-bottom: 0.3rem;
	}

	h1,
	h2 {
		color: #333;
		font-size: 0.5rem;
	}

	h3 {
		margin-top: 20px;
		font-size: 0.4rem;
	}

	p {
		margin-bottom: 15px;
		font-size: 0.3rem;
	}
	
	li {
		font-size: 0.3rem;
	}
</style>